


// import axios from 'axios';
// export const baseURL = 'https://norvelbackend.site/'
// const tokenInstance = axios.create({
//   baseURL: baseURL,
//   timeout: 10000,
// });


// let isRefreshing = false;
// let refreshSubscribers = [];

// tokenInstance.interceptors.request.use(
//   (config) => {
//     const token = getTokenFromLocalStorage();
//     console.log(token,"wdbhwdvwvd")
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// tokenInstance.interceptors.request.use(config => {

//   if (config.url.includes('/')) {

//     delete config.headers.Authorization;
//     localStorage.removeItem('access');
//   }
//   return config;
// });

// tokenInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const { config, response: { status } } = error;
//     const originalRequest = config;
    
//     if (status === 401) {
//       removeTokenFromLocalStorage();
//       if (!isRefreshing) {
//         isRefreshing = true;
//         try {
//           const newToken = await refreshAccessToken();
//           originalRequest.headers.Authorization = `Bearer ${newToken}`;
//           resolveRefreshedRequests(newToken);
//           return axios(originalRequest);
//         } catch (error) {
//           handleTokenRefreshFailure(error);
//           return Promise.reject(error);
//         } finally {
//           isRefreshing = false;
//         }
//       } else {
//         return waitForTokenRefresh(originalRequest);
//       }
//     }
//     return Promise.reject(error);
//   }
// );


// function getTokenFromLocalStorage() {
//   return localStorage.getItem('access');
// }
// function removeTokenFromLocalStorage() {
//   localStorage.removeItem('access');
// }

// async function refreshAccessToken() {
//   const refreshToken = localStorage.getItem('refresh');
//   // const refreshToken = getRefreshTokenFromLocalStorage();
//   if (!refreshToken) {
//     throw new Error('No refresh token available');
//   }

//   const response = await axios.post(`${baseURL}account/api/token/refresh/`, {
//     refresh: refreshToken,
//   });
//   // const response = await axios.post('/account/api/token/refresh/', {
//   //   refreshToken: refreshToken,
//   // });
//   const newToken = response.data.token;
//   localStorage.setItem('access', newToken);
//   return newToken;
// }

// function getRefreshTokenFromLocalStorage() {
//   return localStorage.getItem('refresh');
// }

// function resolveRefreshedRequests(newToken) {
//   refreshSubscribers.forEach(callback => callback(newToken));
//   refreshSubscribers = [];
// }

// function waitForTokenRefresh(originalRequest) {
//   return new Promise((resolve) => {
//     refreshSubscribers.push((token) => {
//       originalRequest.headers.Authorization = `Bearer ${token}`;
//       resolve(axios(originalRequest));
//     });
//   });
// }

// function handleTokenRefreshFailure(error) {
//   console.error('Failed to refresh token:', error);
//   // window.location.href = '/login'; // Redirect to login page on token refresh failure
// }

// export default tokenInstance;

import axios from 'axios';

export const baseURL = 'https://norvel.site/'
const tokenInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
    refreshSubscribers.push(callback);
};

const onRefreshed = (token) => {
    refreshSubscribers.map(callback => callback(token));
};

tokenInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

tokenInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve) => {
                    subscribeTokenRefresh((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        resolve(tokenInstance(originalRequest));
                    });
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const refreshToken = localStorage.getItem('refresh');
                const response = await axios.post(`${baseURL}account/api/token/refresh/`, { refresh: refreshToken });
                const newAccessToken = response.data.access;
                localStorage.setItem('access', newAccessToken);

                onRefreshed(newAccessToken);
                refreshSubscribers = [];

                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return tokenInstance(originalRequest);
            } catch (err) {
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
              
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export default tokenInstance;



