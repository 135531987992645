import React, { useState, useEffect, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MultiSelectDropdown = ({ options, selectedValues, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (optionId) => {
        const updatedValues = selectedValues.includes(optionId)
            ? selectedValues.filter(id => id !== optionId)
            : [...selectedValues, optionId];

        onChange(updatedValues);
    };

    return (
        <div className="custom-dropdown relative" ref={dropdownRef}>
            <div className="flex relative">
                <div className="selected-option border border-gray-200 rounded-full w-[400px] text-[12px] py-2 px-4 text-gray-600 font-bold cursor-pointer" onClick={toggleDropdown}>
                    {selectedValues.length > 0
                        ? options.filter(option => selectedValues.includes(option.id)).map(option => option.name).join(', ')
                        : 'Select Languages'}
                </div>
                <div className="absolute top-2 text-gray-400 right-6">
                    <KeyboardArrowDownIcon style={{ position: 'absolute', fontSize: '18px' }} />
                </div>
            </div>

            {isOpen && (
                <div className="options text-[11px] px-2 py-1 absolute z-30 shadow-md w-[390px] max-h-[150px] overflow-y-auto bg-white font-bold rounded-md">
                    {options.map(option => (
                        <div key={option.id} className="flex items-center gap-2 p-2 hover:bg-gray-50 rounded-full cursor-pointer" onClick={() => handleOptionClick(option.id)}>
                            <input type="checkbox" checked={selectedValues.includes(option.id)} readOnly />
                            <span>{option.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
