import React, { useState, useEffect } from 'react';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import CountryDropDown from './DropDown/CountryDropdown';
import MultiSelectDropdown from './DropDown/MultiSelectDropdown';

const MentorRegistration = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        language: [],
        image: ''
    });

    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
       
        tokenInstance.get('account/api/v1/mentor-languages/')
            .then(response => setLanguages(response.data))
            .catch(error => console.error('Error fetching languages:', error));
    }, []);

    useEffect(() => {
        tokenInstance.get('kanban/api/v1/country_dropdown/?search=&limit=10')
            .then(response => {
                console.log("Fetched Countries:", response.data.results);
                setCountries(response.data.results);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleCountryChange = (countryId) => {
        setFormData(prev => ({ ...prev, country: countryId }));
    };

    const handleLanguageChange = (selectedLanguages) => {
        setFormData(prev => ({ ...prev, language: selectedLanguages }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setUploading(true);
        const uploadData = new FormData();
        uploadData.append('file', file);

        try {
            const response = await tokenInstance.post('home/api/v1/upload-file/', uploadData);
            setFormData(prev => ({ ...prev, image: response.data.url }));
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleSubmit = async (e) => { 
        e.preventDefault();
        try {
            await tokenInstance.post('account/api/v1/register-mentor-user/', formData);
            alert('Mentor registered successfully!');
            setFormData({ first_name: '', last_name: '', email: '', country: '', language: [], image: '' });
        } catch (error) {
            // Handle error response and set error message
            if (error.response && error.response.data) {
                const { error: errorMsg } = error.response.data;
                setErrorMessage(errorMsg); // Set the error message from response
            } else {
                setErrorMessage('An unexpected error occurred.');
            }
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Mentor Registration</h2>
            <form onSubmit={handleSubmit} className="space-y-3">
                <div>
                    <label>First Name:</label>
                    <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} className="border p-2 w-full" />
                </div>
                <div>
                    <label>Last Name:</label>
                    <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} className="border p-2 w-full" />
                </div>
                <div>
                    <label>Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="border p-2 w-full" />
                </div>
                <div>
                    <label>Country:</label>
                    <CountryDropDown options={countries} value={formData.country} onChange={handleCountryChange} />
                </div>
                <div>
                    <label>Language:</label>
                    <MultiSelectDropdown options={languages} selectedValues={formData.language} onChange={handleLanguageChange} />
                </div>
                <div>
                    <label>Upload Image:</label>
                    <input type="file" accept="image/*" onChange={handleImageUpload} className="border p-2 w-full" />
                    {uploading && <p className="text-blue-500">Uploading...</p>}
                    {formData.image && <img src={formData.image} alt="Uploaded" className="w-20 h-20 mt-2" />}
                </div>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2">Submit</button>
                {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </form>
        </div>
    );
};

export default MentorRegistration;







