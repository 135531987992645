import React, { createContext, useContext, useState } from 'react';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import { useNavigate } from 'react-router';

// Create a context for mentor-related state
const MentorContext = createContext();


// Provider component to wrap the application and share the state
export const MentorProvider = ({ children }) => {
    const [mentorList, setMentorList] = useState([]);
    const [roomId, setRoomID] = useState(null);
     const [callResponse, setCallResponse] = useState([]);
    
    const [studentId, setStudentId] = useState(null);
    const [requestList, setRequestList] = useState([]);
    const navigate= useNavigate()


      const handleCall = async (roomId) => {
            if (!roomId) {
                console.error("Room ID is not set.");
                return;
            }
            try {
                const response = await tokenInstance.post("calls/api/v1/voice-call-room-join-request/", {
                    room: roomId,
                });
             
                setCallResponse(response.data);
    
                setRoomID(response.data.room.id);
                setStudentId(response.data.student.id);
    
    
                if (response.status === 201) {
                    fetchStudentRequestList(response.data.room.id);
                   
    
                    console.log("hello call request list")
                    navigate('/callwaiting', {
                        state: {
                            callResponse: response.data,
    
                        },
                    });
    
    
    
                } else {
                    console.log("Unexpected status code:", response.data);
                }
            } catch (err) {
                console.log("Error posting call API:", err.message);
            }
        };

    const fetchMentorList = async () => {
        try {
            const response = await tokenInstance.get("calls/api/v1/mentors_list/");
            setMentorList(response.data);
        } catch (err) {
            console.log("API fetching error", err.message);
        }
    };

    const fetchStudentRequestList = async (roomIdParam) => {
        try {
            const id = roomIdParam || roomId; // Use the parameter if provided, fallback to state
            if (id) {
                console.log("Fetching request list for room ID:", id);
                const response = await tokenInstance.get(`calls/api/v1/voice-call-room-request-list/?room_id=${id}`);
                setRequestList(response.data);
            }
        } catch (err) {
            console.log('Error fetching API:', err.message);
        }
    };
    
    return (
        <MentorContext.Provider
            value={{
                mentorList,
                setMentorList,
                roomId,
                setRoomID,
              
                studentId,
                setStudentId,
                requestList,
                setRequestList,
                fetchMentorList,
                fetchStudentRequestList,
                handleCall,
            }}
        >
            {children}
        </MentorContext.Provider>
    );
};

// Custom hook to use the MentorContext
export const useMentorContext = () => useContext(MentorContext);
