import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import { createClient } from 'agora-rtc-sdk-ng';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useMentorContext } from './MentorContext';
import { Modal, Box } from '@mui/material';


const CallWaiting = () => {
    const location = useLocation();



    const [studentId, setStudentId] = useState(null);
    const [socket, setSocket] = useState(null);
    const [requestSocket, setRequestSocket] = useState(null);
    const [agoraToken, setAgoraToken] = useState(null);
    const [client, setClient] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    const [channelName, setChannelName] = useState(null);
    const [uid, setUid] = useState(null);
    const [isMuted, setIsMuted] = useState(false); // Mute state
    const [localAudioTrack, setLocalAudioTrack] = useState(null); // Track state
    const navigate = useNavigate();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const[allowNavigation,setAllowNavigation]=useState(false)
    


    const appId = 'f9396bbbaad64783abd978a28e727ce2';
    const {
        callResponse,
        fetchStudentRequestList,
        requestList,
        roomId
    } = useMentorContext();







    // useEffect(() => {
    //     if (callResponse && callResponse.room.id) {
    //         setRoomID(callResponse.room.id)


    //         setStudentId(callResponse.student?.id);
    //     }
    // }, [callResponse]);



    console.log(roomId, "hllo room id here")



    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const userId = userInfo?.id;

    useEffect(() => {
        if (userId) {
            const wsUrl = `wss://norvelbackend.site/ws/voice-call-token/voice_call_room_${userId}/`;
            const newSocket = new WebSocket(wsUrl);
            setSocket(newSocket);

            newSocket.onopen = () => {
                console.log('WebSocket connection established.');
            };

            newSocket.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    if (message.type === 'voice_call_websocket_receiver') {
                        setAgoraToken(message.event.token);
                        setUid(message.event.uid);
                        setChannelName(message.event.channel_name);
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            newSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            newSocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };

            return () => {
                newSocket.close();
            };
        }
    }, [userId]);
    useEffect(() => {
        if (userId) {
            // WebSocket for voice call requests
            const wsRequestUrl = `wss://norvelbackend.site/ws/voice-call-requests/voice_call_requests_${userId}/`;
            const newRequestSocket = new WebSocket(wsRequestUrl);
            setRequestSocket(newRequestSocket);

            newRequestSocket.onopen = () => {
                console.log('Request WebSocket connection established.');
            };

            newRequestSocket.onmessage = (event) => {
                console.log('Raw WebSocket message:', event.data);
                try {
                    const message = JSON.parse(event.data);
                    console.log('Parsed message:', message);
                    fetchStudentRequestList();
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };


            newRequestSocket.onerror = (error) => {
                console.error('Request WebSocket error:', error);
            };

            newRequestSocket.onclose = (event) => {
                console.log('Request WebSocket connection closed:', event);
            };

            // Clean up on unmount
            return () => {
                newRequestSocket.close();
            };
        }
    }, [userId]);
    const joinAgoraChannelStudent = async () => {
        if (!agoraToken || !channelName || !uid) {
            console.error("Missing Agora token, channel name, or UID.");
            return;
        }

        try {
            const agoraClient = createClient({ mode: 'rtc', codec: 'vp8' });
            setClient(agoraClient);

            // Create and publish local audio track
            const track = await AgoraRTC.createMicrophoneAudioTrack();
            setLocalAudioTrack(track);
            await agoraClient.join(appId, channelName, agoraToken, uid);
            await agoraClient.publish([track]);
            console.log("Student audio track published.");

            // Handle remote user subscriptions
            agoraClient.on("user-published", async (user, mediaType) => {
                await agoraClient.subscribe(user, mediaType);
                console.log("Subscribed to remote user:", user.uid);

                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                    console.log("Playing remote audio from user:", user.uid);
                }
            });

            agoraClient.on("user-unpublished", (user) => {
                console.log("Remote user unpublished:", user.uid);
            });

            setIsJoined(true);
        } catch (error) {
            console.error("Error joining Agora channel (Student):", error);
        }
    };


    const toggleMute = () => {
        if (localAudioTrack) {
            if (isMuted) {
                localAudioTrack.setEnabled(true); // Unmute
                console.log("Microphone unmuted.");
            } else {
                localAudioTrack.setEnabled(false); // Mute
                console.log("Microphone muted.");
            }
            setIsMuted(!isMuted);
        }
    };

    const leaveAgoraChannel = async () => {
        if (client) {
            try {
                await client.leave();
                setIsJoined(false);
                setLocalAudioTrack(null); // Clear local track
                console.log('Left Agora channel.');
            } catch (error) {
                console.error('Error leaving Agora channel:', error);
            }
        }
    };

    useEffect(() => {
        if (agoraToken && channelName && uid) {
            joinAgoraChannelStudent();
        }
    }, [agoraToken, channelName, uid]);

    const callEnd = async () => {
        if (!roomId) {
            console.error("Room ID is missing.");
            return;
        }

        try {
            const response = await tokenInstance.post("calls/api/v1/disconnect_voice_call/", {
                room: roomId,
            });

            if (response.status === 200) {
                console.log("Call disconnected successfully.");


                if (client) {
                    await client.leave();
                    client.remoteUsers.forEach((user) => {
                        if (user.audioTrack) {
                            user.audioTrack.stop();
                            user.audioTrack.close();
                        }
                    });

                    if (localAudioTrack) {
                        localAudioTrack.stop();
                        localAudioTrack.close();
                    }

                    setClient(null);
                    setLocalAudioTrack(null);
                    setIsJoined(false);
                }

                navigate("/mentor");
            } else {
                console.error("Failed to disconnect call. Status:", response.status);
            }
        } catch (error) {
            console.error("Error ending the call:", error);
        }
    };

     useEffect(() => {
                 const handleBeforeUnload = (event) => {
                    
                    
                     event.preventDefault();
                     callEnd()
                    
         
                    
                   
                     return"";
                 };
                 
         
                 // Attach the event listener
                 window.addEventListener("beforeunload", handleBeforeUnload);
         
                 // Cleanup the event listener when the component unmounts
                 return () => {
                     window.removeEventListener("beforeunload", handleBeforeUnload);
                 };
             }, []);
    

    // Handle browser refresh or navigation attempt
  

   
    
    
    



    


    return (
        <div className='flex justify-center flex-col items-center py-6'>
            <div className='flex flex-col items-center  w-[500px] bg-gray-400 rounded-md py-4  px-4'>
                <div className='flex flex-row gap-10'>
                    <div className='flex items-center flex-col '>
                        <div className='w-[70px] h-[70px]'>
                            <img src="/img2.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                        </div>
                        <div className='text-[11px] pt-sans-regular'>student</div>
                    </div>

                    <div>
                        <img src="/icon.png" className='w-[100%] h-[100%] object-contain' />
                    </div>
                    <div className='flex items-center flex-col '>
                        <div className='w-[70px] h-[70px]'>
                            <img src="/img3.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                        </div>
                        <div className='text-[11px] pt-sans-regular'>Mentor</div>
                    </div>

                </div>
                {isJoined ? (
                    <div className='flex gap-8'>
                        <div>
                            <button
                                onClick={toggleMute}
                                className={`mt-4 py-1 mx-4 px-4 rounded ${isMuted ? 'bg-red-500' : 'bg-green-500'} text-[12px] text-white`}
                            >
                                {isMuted ? 'Unmute' : 'Mute'}
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={callEnd}
                                className="mt-4 py-1 px-4 text-[12px] rounded bg-red-600 text-white"
                            >
                                End Call
                            </button>
                        </div>

                    </div>


                ) : <div className='text-[12px] my-6 bg-white px-4 py-1 rounded-full'>Mentor will accept the call In a moment.</div>}





            </div>

            <div>
                <div className="font-bold text-m4 px-4 py-2">Student side request list</div>
                {requestList?.map((request, index) => {
                    return (
                        <div key={index} className="flex gap-10 px-4 py-4">
                            <div className="text-[13px] font-medium">{request.student?.first_name}</div>
                            <div className="bg-yellow-500 text-[12px] rounded-full px-4 py-1">{request.status}</div>
                        </div>
                    );
                })}
            </div>
            <div>
           
            </div>

        </div>


    );



};

export default CallWaiting;


