import React, { useRef, useEffect, useState } from 'react';
import GroupPNG from '../assets/Group.png';
import axiosInstance from '../components/axiosInstance/axiosInstance';
import { ReactComponent as MastersIcon } from '../assets/masters.svg';
import { ReactComponent as DiplomaIcon } from '../assets/diploma.svg';
import { ReactComponent as PhDIcon } from '../assets/phd.svg';
import { ReactComponent as BachelorsIcon } from '../assets/bachelors.svg';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Header from '../components/header/Header';
import { DropdownProvider } from '../components/DropdownContext/DropdownContext';
import { useDropdown } from '../components/DropdownContext/DropdownContext';

import Logout from '../components/authetication/Logout';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const LandingPage = () => {
    const [educationLevels, setEducationLevels] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('Masters');
    const { isDropdownVisible, setDropdownVisible } = useDropdown();
     const [homeBannerCarousel, setHomeBannerCarousel] = useState([]);
     const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const dropdownRef = useRef(null);


    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('home/api/v1/home-landing-page/');
            setEducationLevels(response.data.education_level);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('selectedEducationType')) {
            localStorage.removeItem('selectedEducationType');
        }
        fetchData();
    }, []);


    // Mapping of course names to SVG icons and background colors
    const courseMappings = {
        'Masters': {
            icon: <MastersIcon className="w-8 h-8" />,
            bgColor: '#f7dec0',
        },
        'Diploma': {
            icon: <DiplomaIcon className="w-8 h-8" />,
            bgColor: '#d4f7c0',
        },
        'Phd / Research': {
            icon: <PhDIcon className="w-8 h-8" />,
            bgColor: '#c0d4f7',
        },
        'Bachelors': {
            icon: <BachelorsIcon className="w-8 h-8" />,
            bgColor: '#f7c0d4',
        },
    };

    const handleTileClick = (eduType) => {
        setSelectedCourse(eduType); // Update the selected course type
        navigate('/home', { state: { selectedEducationType: eduType } }); // Pass selected course type to CourseFilter
    };
    const mentorNavigate = () => {
        navigate('/room')
    }
    const studentNavigate = () => {
        navigate('/mentor')
    }

    const fetchBannerdetails = async () => {
        try {
            const response = await axiosInstance.get(
                `account/api/v1/home-company-settings/?page=home page`
            );
            setHomeBannerCarousel(response.data.banners);
        } catch (err) {
            console.error("Error fetching university details:", err.message);
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };
        useEffect(() => {
        
            fetchBannerdetails("");
          
        }, []);
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            pauseOnHover: false,
            cssEase: "linear",
          };
          
          const MyCarousel = ({ banners }) => {
            const bannerList = Array.isArray(banners) ? banners : [];
        
            if (loading) {
                return (
                    <div className="lg:rounded-[20px] lg:overflow-hidden flex items-center justify-center 
                        h-[130px] sm:h-[250px] md:h-[300px] lg:h-[400px] w-full bg-gray-200">
                        <p className="text-gray-500 text-sm sm:text-base">Loading...</p>
                    </div>
                    
                );
            }
        
            if (bannerList.length === 0) {
                return (
                    <div className="lg:rounded-[20px] lg:overflow-hidden">
                      <img src="homenew.PNG" alt="Slide 1" className="w-full h-full object-cover" />
                    </div>
                  );
            }
        
            const adjustedSettings = {
                ...settings,
                autoplay: bannerList.length > 1,
                infinite: bannerList.length > 1,
                dots: bannerList.length > 1,
                arrows: bannerList.length > 1,
            };
          
            return (
                <div className="relative">
                <div className="lg:rounded-[20px] lg:overflow-hidden">
                    {bannerList.length === 1 ? (
                        <img
                            src={bannerList[0].web_banner_img}
                            alt={`Banner ${bannerList[0].id}`}
                            className="w-full h-full object-cover"
                        />
                    ) : (
                        <Slider {...adjustedSettings}>
                            {bannerList.map((banner) => (
                                <div key={banner.id} className="lg:overflow-hidden">
                                    <a href={banner.banner_url} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={banner.web_banner_img}
                                            alt={`Banner ${banner.id}`}
                                            className="w-full h-full object-cover"
                                        />
                                    </a>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
            );
          };
          
      

    return (

        <div className='overflow-hidden'>

            <div className="main div relative lg:mx-10 ">
                {/* <div className="flex items-center gap-2">
                    <div className="w-[22px] overflow-hidden">
                        <img
                            className="w-full h-full object-contain"
                            src={GroupPNG}
                            alt="logo"
                        />
                    </div>
                    <div className="font-bold text-3xl">norvel</div>
                </div> */}
               
                <Header />
                

                {isDropdownVisible && (
                    <div ref={dropdownRef} className="absolute top-14 md:top-14 right-4  md:right-8 px-2 md:px-6 bg-gray-100 border rounded shadow-lg z-0">
                        <div className="py-1 md:py-1  cursor-pointer text-[13px] pt-sans-regular"><Logout /></div>
                    </div>
                )}
                <div className='px-4'>
                    <div className="pt-2 lg:hidden">
                        <div className="pt-sans-bold text-[20px]">Global Study Network</div>
                        <div className="text-[15px] pb-2 pt-sans-regular">
                        Your Gateway to Global Education Opportunities
                        </div>


                        {/* <div className="w-full h-auto md:h-[40vh] bg-[#00265f] rounded-xl ">
                            <img
                                className="w-[100%] h-[100%] object-contain"
                                src="./Landing.png"
                                alt="landing"
                            />
                        </div> */}
                    </div>
                    <MyCarousel banners={homeBannerCarousel} />
                    <div className=" pt-4 pb-2 text-[18px] pl-2 pt-sans-bold">Study Programs</div>
                    <div className="gap-2 grid grid-cols-2 md:grid-cols-4">
                        {educationLevels.map((level) => {
                            const { icon, bgColor } = courseMappings[level.name] || {}; // Access course mappings
                            return (
                                <div
                                    key={level.id}
                                    className="p-4 rounded-xl cursor-pointer "
                                    onClick={() => handleTileClick(level.name)}
                                    style={{ backgroundColor: bgColor || '#f0f0f0' }}
                                >
                                    <div className="bg-white  rounded-xl flex p-2 "
                                        style={{ width: 'fit-content' }}>
                                        {icon || <div className="text-gray-500">Icon Missing</div>}
                                    </div>
                                    <div className="pt-2 text-[15px] pt-sans-bold">{level.name}</div>
                                    <div className="text-[12px]">Fees starts from</div>
                                    <div className="text-[14px] pt-sans-bold">Rs. {level.minimum_fee} Lakhs</div>
                                    <div className='flex items-center justify-end'>

                                        <div className="bg-white opacity-60  pl-8 flex items-center justify-center animate-bounce   rounded-xl" style={{ width: 'fit-content' }} >
                                            <KeyboardArrowRightIcon style={{ fontSize: '20px' }} />
                                        </div>
                                    </div>

                                </div>
                            );
                        })}
                    </div>

                    <div className=" pt-4  text-[18px] pl-2 pt-sans-bold">Accomadations</div>

                    <div className="bg-[#f7c3f4]  rounded-xl my-4">
                        <div className='flex items-center gap-4 px-3 py-3'>
                            <div className='h-[120px] w-[300px]'>
                                <img className='w-full h-full object-cover' src="./accomodations.jpeg" alt="buliding photo" />
                            </div>
                            <div className="flex flex-col w-full gap-4">

                                <div className="flex flex-col  ">
                                    <div className="text-[16px] pt-sans-bold">Student Housing</div>
                                    <div className="text-[14px] pt-sans-bold">Coming soon .....</div>
                                </div>
                                {/* <div className='flex items-center justify-end '>

                                            <div className="bg-white opacity-60 mb-2  pl-8 flex items-center justify-center    rounded-xl" style={{ width: 'fit-content' }} >
                                                <KeyboardArrowRightIcon style={{ fontSize: '20px' }} />
                                            </div>
                                        </div> */}
                            </div>

                        </div>



                    </div>
                    {/* <div className=" pt-4 pb-2 text-[18px] pl-2 pt-sans-bold">Student Connect</div> */}

                    {/* <div className="bg-[#f7c3f4]  rounded-xl mb-4 ">
                        <div className='flex items-center gap-4 px-3 py-3'>
                            <div className='h-[120px] w-[300px]'>
                                <img className='w-full h-full object-cover' src="./accomodations.jpeg" alt="buliding photo" />
                            </div>
                            <div className="flex flex-col w-full gap-4">

                                <div className="flex flex-row  gap-4 ">
                                    <div className="text-[16px] pt-sans-bold bg-white opacity-60 px-4 py-1 rounded-md cursor-pointer " onClick={studentNavigate}>Student</div>
                                    <div className="text-[16px] pt-sans-bold bg-white opacity-60 px-4 py-1 rounded-md cursor-pointer " onClick={mentorNavigate}>Mentor</div>
                                </div>
                               
                            </div>

                        </div>



                    </div> */}

                </div>

            </div>
            <Footer />

        </div>

    );
};

export default LandingPage;


